// Import React Libraries
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

// Import Components
import MapButton from './MapButton';

// Import Datas
import { address, addressGoogleMapUrl } from '../../datas/pageContent/accessPageContent';

// Import Style
import { AddressWrapper, AddressContainer, MapContainer } from '../../utils/style/js/Access_Style/Address_Style';
import { PageSubtitle, StyledP } from '../../utils/style/js/Font_Style';

function AddressDisplay () {
  const { t } = useTranslation();

  const [layoutMobile, setLayoutMobile] = useState(window.innerWidth <= 510);

  useEffect(() => {
    const handleResize = () => {
      console.log('Window resized!');
      if (window.innerWidth <= 510) {
        setLayoutMobile(true);
      } else {
        setLayoutMobile(false);
      }
    };

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, [window.innerWidth]);

  return (
      <AddressWrapper id='addressWrapper'>
        <AddressContainer>
          <PageSubtitle>{t('Address')}</PageSubtitle>
          <StyledP>{`${address.Name}`}</StyledP>
          { layoutMobile === true
            ? <>
                <StyledP>{`${address.Street}`}</StyledP>
                <StyledP>{`${address.City} ${address.PostCode}`}</StyledP>
              </>
            : <StyledP>{`${address.Street} ${address.City} ${address.PostCode}`}</StyledP>
          }
          <MapButton/>
        </AddressContainer>
        <MapContainer id='mapContainer'>
          <iframe
            title = "googleMap"
            src={addressGoogleMapUrl}
            width="100%"
            height="100%"
            style={{ border: '0' }}
            allowFullScreen=""
            loading="lazy"
            referrerPolicy="no-referrer-when-downgrade"
          ></iframe>
        </MapContainer>
      </AddressWrapper>
  );
}

export default AddressDisplay;
