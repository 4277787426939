// Import React Libraries
import React from 'react';

// Import PropType
import PropTypes from 'prop-types';

// Import Assets
import { logoBlack } from '../../datas/Assets/logo';

// Import Datas
import { contactList } from '../../datas/pageContent/contactPageContent';

// Import Style
import {
  Overlay, Modal,
  TitleContainer, LogoContainer, Logo,
  AlertContainer, AlertButton
} from '../../utils/style/js/Alert_Style/Alert_Style';
import { StyledP } from '../../utils/style/js/Font_Style';

const CustomAlert = ({ message, setShowAlert }) => {
  const handleCloseAlert = () => {
    setShowAlert(false);
  };

  return (
    <Overlay>
      <Modal>
        <TitleContainer>
          <LogoContainer>
            <Logo src={logoBlack}/>
          </LogoContainer>
          <StyledP>{contactList.WebSiteAddress}</StyledP>
        </TitleContainer>
        <AlertContainer>
          <StyledP>{message}</StyledP>
          <AlertButton onClick={() => handleCloseAlert()}>OK</AlertButton>
        </AlertContainer>
      </Modal>
    </Overlay>
  );
};

CustomAlert.propTypes = {
  message: PropTypes.string,
  setShowAlert: PropTypes.func.isRequired
};

export default CustomAlert;
