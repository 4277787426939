// Import React Libraries
import React, { useState } from 'react';
import { Helmet } from 'react-helmet';
import { useTranslation } from 'react-i18next';

// Import Component
import TopIllustration from '../../components/TopIllustration/TopIllustration';
import ContactInformation from '../../components/Contact/contactInformation';
import ContactFormComponent from '../../components/Contact_Form/Contact_Form';
import LoadingOverlayComponent from '../../components/LoadingComponent/LoadingOverlay_Component';
import CustomAlert from '../../components/CustomAlert/CustomAlert';

// Import Datas
import { contactPageContent } from '../../datas/pageContent/contactPageContent';

// Import Illustration
import contactTopIllustration from '../../assets/topIllustrations/ContactTopIllustration.jpg';

// Import Style
import { PageWrapper } from '../../utils/style/js/GlobalStyle';
import { ContactWrapper } from '../../utils/style/js/Contact/ContactStyle';

function Contact () {
  const { t } = useTranslation();
  const [loaderState, setLoaderState] = useState(false);
  const [showAlert, setShowAlert] = useState(false);
  const [messageAlert, setMessageAlert] = useState();

  return (
    <>
      <Helmet>
        <title>Les Bois Flottés - {t(`${contactPageContent.pageTitle}`)}</title>
        <meta
          name="description"
          content={`${contactPageContent.referencement.map((keyWords) => (keyWords)).join(', ')} `}
        />
      </Helmet>
      <TopIllustration image={contactTopIllustration} title={'Contact'}/>
      <PageWrapper id='contactPageWrapper'>
        <ContactWrapper id='contactWrapper'>
          <ContactInformation/>
          <ContactFormComponent setLoaderState={setLoaderState} setShowAlert={setShowAlert} setMessageAlert={setMessageAlert}/>
        </ContactWrapper>
      </PageWrapper>
      {
        loaderState &&
          <LoadingOverlayComponent message={`${t('SendingMessage')}`}/>
      }
      {showAlert && (
        <CustomAlert
          message={messageAlert}
          setShowAlert={setShowAlert}
        />
      )}
      </>
  );
}

export default Contact;
