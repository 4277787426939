export const accessPageContent = {
  pageTitle: 'Access',
  referencement: [
    'Les Bois Flottés', 'La Verne', 'Fabregas',
    'Plage', 'Location',
    'Localisation'
  ],
  transport: [
    'Car', 'Train', 'Plane'
  ],
  transportIconName: {
    Car: 'faCarSide',
    Train: 'faTrain',
    Plane: 'faPlaneDeparture'
  },
  transportIconColors: {
    Car: 'icons',
    Train: 'icons',
    Plane: 'icons'
  },
  transportDescription: {
    Car: [
      'CarTransportDescription1'
    ],
    Train: [
      'TrainTransportDescription1',
      'TrainTransportDescription5'
    ],
    Plane: [
      'PlaneTransportDescription1',
      'PlaneTransportDescription3'
    ]
  },
  parkingDescription: 'ParkingSpot'
};

export const address = {
  Name: "'Les Bois Flottés' La Verne",
  Street: '150 Boulevard Garnault',
  City: 'La Seyne sur Mer',
  PostCode: '83 500'
};

export const addressGoogleMapUrl =
'https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2914.446246432536!2d5.874743174693204!3d43.074112789693906!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x12c90317d9777a33%3A0x39e05a265277e6d0!2s150%20Bd%20Garnault%2C%2083500%20La%20Seyne-sur-Mer!5e0!3m2!1sfr!2sfr!4v1698309848257!5m2!1sfr!2sfr';
