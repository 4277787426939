// Import Styled
import styled from 'styled-components';
import colors from '../../../colors/colors';

export const Overlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 9999;
`;

export const Modal = styled.div`
  background-color: white;
  padding: 20px;
  border-radius: 15px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  width: 250px;
  height: 125px;
`;

export const AlertContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 10px;
`;

export const TitleContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`;

export const LogoContainer = styled.div`
  height: 25px;
  width: 25px;

  margin-right: 15px;
`;

export const Logo = styled.img`
  height: 100%;
  width: 100%;
`;

export const AlertButton = styled.button`
  height: 40px;
  width: 75px;

  font-family: cursive;

  cursor: pointer;

  border-radius: 10px;
  border: none;

  color: ${colors.navLinks};
  background: ${colors.backgroundHeaderFooter};
  box-shadow: 0 0 10px ${colors.interactifElement};

  &:hover {
    color: ${colors.backgroundHeaderFooter};
    background: ${colors.navLinks};
    box-shadow: 0 0 10px ${colors.interactifElementHover};
  }
`;
