// Import Styled
import styled, { keyframes } from 'styled-components';

// Import Colors
import colors from '../../../colors/colors';

const spin = keyframes`
  to {
    transform: rotate(360deg);
  }
`;

export const LoadingOverlayContainer = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 9999;
`;

export const SpinnerContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-evenly;
  text-align: center;
  width: 250px;
  height: 125px;
  padding: 20px;
  background-color: white;
  border-radius: 15px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
`;

export const Spinner = styled.div`
  width: 40px;
  height: 40px;
  border: 4px solid rgba(0, 0, 0, 0.1);
  border-left-color: ${colors.interactifElement};
  border-radius: 50%;
  animation: ${spin} 1s linear infinite;
`;
