// Import React Librairies
import React from 'react';

//  Import Asset
import { logoWhite } from '../../../datas/Assets/logo';

// Import Style
import { SizedIMG } from '../../../utils/style/js/LogoStyles';

function Logo () {
  return (
    <div id='logoContainer'>
      <SizedIMG src={logoWhite} alt='Les Bois Flottés'></SizedIMG>
    </div>
  );
}

export default Logo;
