// Import React Libraries
import React from 'react';

// Import PropType
import PropTypes from 'prop-types';

// Import Style
import {
  LoadingOverlayContainer,
  Spinner,
  SpinnerContainer
} from '../../utils/style/js/LoadingComponent/LoadingComponent_Styles';
import { StyledP } from '../../utils/style/js/Font_Style';

function LoadingOverlayComponent ({ message }) {
  return (
    <LoadingOverlayContainer>
      <SpinnerContainer>
        <Spinner />
        <StyledP>{message}</StyledP>
      </SpinnerContainer>
    </LoadingOverlayContainer>
  );
};

LoadingOverlayComponent.propTypes = {
  message: PropTypes.func.isRequired
};

export default LoadingOverlayComponent;
