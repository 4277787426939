// Import PropTypes
import PropTypes from 'prop-types';

// Import Assets
import HousingPhoto1 from '../../assets/photos/pic15.jpg';
import HousingPhoto2 from '../../assets/photos/pic9.jpg';
import HousingPhoto3 from '../../assets/photos/pic12.jpg';
import HousingPhoto4 from '../../assets/photos/pic5.jpg';
import HousingPhoto5 from '../../assets/photos/pic8.jpg';
import HousingPhoto6 from '../../assets/photos/pic13.jpg';

export const housingPhotos = [
  {
    src: HousingPhoto1
  },
  {
    src: HousingPhoto2
  },
  {
    src: HousingPhoto3
  },
  {
    src: HousingPhoto4
  },
  {
    src: HousingPhoto5
  },
  {
    src: HousingPhoto6
  }
];

export const PhotoPropTypes = PropTypes.shape({
  src: PropTypes.string.isRequired
});

export const PhotosPropTypes = PropTypes.arrayOf(PhotoPropTypes).isRequired;
