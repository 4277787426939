// Import React Libraries
import React, { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';

// Import Components
// import CopyEmailLink from './copyMail';

// Import Datas
import { contactList } from '../../datas/pageContent/contactPageContent';

// Import Style
import { ProfilePic } from '../../utils/style/js/Contact/ContactStyle';
import { StyledP } from '../../utils/style/js/Font_Style';
import { ContactInformationContainer } from '../../utils/style/js/Contact/ContactInformation_Style';

function ContactInformation () {
  const { t } = useTranslation();
  const containerRef = useRef(null); // Create a ref for the container
  const [containerWidth, setContainerWidth] = useState(0);

  useEffect(() => {
    if (containerRef.current) {
      const width = containerRef.current.getBoundingClientRect().width;
      setContainerWidth(width);
    }

    // Optionally, listen for window resize events if you need dynamic resizing
    const handleResize = () => {
      if (containerRef.current) {
        setContainerWidth(containerRef.current.getBoundingClientRect().width);
      }
    };

    window.addEventListener('resize', handleResize);

    // Cleanup the event listener
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, [window.innerWidth]);

  /*
  useEffect(() => {
    console.log(`containerWidth : ${containerWidth}`);
  }, [containerWidth]);
  */

  return (
    <ContactInformationContainer id='contactInformationContainer' ref={containerRef}>
      { containerWidth >= 500
        ? <StyledP>{`${t('ContactUs1')} ${t('ContactUs2')}`}</StyledP>
        : <>
            <StyledP>{t('ContactUs1')}</StyledP>
            <StyledP>{t('ContactUs2')}</StyledP>
          </>
      }
      <StyledP >{contactList.Name}</StyledP>
      { /* <StyledP >{t('Email')} : <CopyEmailLink email={contactList.Email} /></StyledP> */ }
      <ProfilePic src={contactList.ProfilePic} alt="PIC"/>
    </ContactInformationContainer>
  );
}

export default ContactInformation;
