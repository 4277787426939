export const housingPageContent = {
  pageTitle: 'Housing',
  referencement: [
    'Les Bois Flottés', 'La Verne', 'Fabregas',
    'Plage', 'Location',
    'Logement'
  ],
  presentation1: [
    'presentationHousing1', 'presentationHousing1Bold',
    'presentationHousing2', 'presentationHousing2Bold',
    'presentationHousing3', 'presentationHousing3Bold',
    'presentationHousing4', 'presentationHousing4Bold',
    'presentationHousing5'
  ],
  presentationTypoStyle1: [
    'normal', 'bold',
    'normal', 'bold',
    'normal', 'bold',
    'normal', 'bold',
    'normal'
  ],
  presentation2: [
    'presentationHousing6', 'presentationHousing6Bold',
    'presentationHousing7', 'presentationHousing7Bold',
    'presentationHousing8', 'presentationHousing8Bold',
    'presentationHousing9'
  ],
  presentationTypoStyle2: [
    'normal', 'bold',
    'normal', 'bold',
    'normal', 'bold',
    'normal'
  ],
  presentation3: [
    'presentationHousing10', 'presentationHousing10Bold',
    'presentationHousing11', 'presentationHousing11Bold',
    'presentationHousing12'
  ],
  presentationTypoStyle3: [
    'normal', 'bold',
    'normal', 'bold',
    'normal'
  ],
  presentation4: [
    'presentationHousing13'
  ],
  presentationTypoStyle4: [
    'normal'
  ],
  presentation5: [
    'presentationHousing14'
  ],
  presentationTypoStyle5: [
    'normal'
  ]
};

/* ICONS */
export const housingIconsListDataGeneral = [
  {
    icon: 'faVectorSquare',
    iconColor: 'icons',
    iconText: 'Area'
  },
  {
    icon: 'faWifi',
    iconColor: 'icons',
    iconText: 'Wifi'
  },
  {
    icon: 'faEye',
    iconColor: 'icons',
    iconText: 'GardenView'
  }
];

export const housingIconsListDataIndoor = [
  {
    icon: 'faBed',
    iconColor: 'icons',
    iconText: 'QueenSize'
  },
  {
    icon: 'faShower',
    iconColor: 'icons',
    iconText: 'Shower'
  },
  {
    icon: 'faBlanket',
    iconColor: 'icons',
    iconText: 'Towels&Sheets'
  }
];

export const housingIconsListDataOutdoor = [
  {
    icon: 'faBicycle',
    iconColor: 'icons',
    iconText: 'Bicycle'
  },
  {
    icon: 'faUmbrellaBeach',
    iconColor: 'icons',
    iconText: 'Umbrella'
  }
];
