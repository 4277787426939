// Import React Libraries
import React, { useState, useCallback } from 'react';
import Gallery from 'react-photo-gallery';
import Carousel, { Modal, ModalGateway } from 'react-images';

// Import Components
import TopIllustration from '../../components/TopIllustration/TopIllustration';

// Import Assets
import { galleryPagePhotos } from '../../datas/Assets/galleryPagePhotos';
import galleryIllustration from '../../assets/topIllustrations/GalleryTopIllustration.jpg';

// Import Style
import { PageWrapper } from '../../utils/style/js/GlobalStyle';

function Galleryx () {
  const [currentImage, setCurrentImage] = useState(0);
  const [viewerIsOpen, setViewerIsOpen] = useState(false);

  const openLightbox = useCallback((event, { photo, index }) => {
    setCurrentImage(index);
    setViewerIsOpen(true);
  }, []);

  const closeLightbox = () => {
    setCurrentImage(0);
    setViewerIsOpen(false);
  };

  return (
    <>
      <TopIllustration image={galleryIllustration} title={'Gallery'}/>
      <PageWrapper id='galleryPageWrapper'>
        <Gallery photos={galleryPagePhotos} onClick={openLightbox} targetRowHeight={150} margin={5}/>
        <ModalGateway>
          {
            viewerIsOpen
              ? (
                  <Modal onClose={closeLightbox}>
                    <Carousel
                      currentIndex={currentImage}
                      views={galleryPagePhotos.map(x => ({
                        ...x,
                        srcset: x.srcSet,
                        caption: x.title
                      }))}
                    />
                  </Modal>
                )
              : null
          }
        </ModalGateway>
    </PageWrapper>
  </>
  );
}

export default Galleryx;

// Carousel prop :
// showNavigationOnTouchDevice
