// Import Assets
import julieJM from '../../assets/profiles/JulieJM.png';

export const contactPageContent = {
  pageTitle: 'Contact',
  referencement: [
    'Les Bois Flottés', 'La Verne', 'Fabregas',
    'Plage', 'Location',
    'Contact'
  ]
};

export const contactList = {
  Name: 'Julie & J.Manuel',
  Telephone: '061abcd',
  Email: 'jussieux@free.fr',
  ProfilePic: julieJM,
  WebSiteAddress: 'lesboisflotteslaverne.fr'
};
