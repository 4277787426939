export const navigationBarContent = [
  {
    pageLinkTitle: 'Home',
    pageLink: '',
    dropDown: false
  },
  {
    pageLinkTitle: 'Housing',
    pageLink: 'Housing',
    dropDown: false
  },
  {
    pageLinkTitle: 'Comments',
    pageLink: 'Comments',
    dropDown: false
  },
  {
    pageLinkTitle: 'Prices',
    pageLink: 'Prices-title',
    dropDown: false
  },
  {
    pageLinkTitle: 'Gallery',
    pageLink: 'Gallery',
    dropDown: false
  },
  {
    pageLinkTitle: 'Access-title',
    pageLink: 'Access',
    dropDown: false
  },
  {
    pageLinkTitle: 'Contact',
    pageLink: 'Contact',
    dropDown: false
  }
];
